import { createSlice } from "@reduxjs/toolkit";
import { fetchAccountDetailAsync, fetchLinkTokenAsync, fetchPublicAccessTokenAsync } from "../thunks/TransactionThunk";
import { STATUS } from "../../types/CommonType";

interface TransactionState {
    link_token: string | null;
    hostedLink:string|null;
    linkStatus: string;
    error: string | null;
    publicAccessToken: string | null;
    publicAccessTokenStatus: string;
    accounts: AccountInfo[] | null;
    accountStatus: string | null; 
}

const initialState: TransactionState = {
    link_token: null,
    hostedLink: null,
    linkStatus: STATUS.IDLE,
    error: null,
    publicAccessToken: null,
    publicAccessTokenStatus: STATUS.IDLE,
    accounts: null, 
    accountStatus: STATUS.IDLE, 
};
interface Institution {
    id: number;
    logo_content: string | null;
    institution_name: string;
    institution_id: string;
    url: string;
  }
  
  interface AccountInfo {
    id: number;
    account_id: string;
    item_id: string;
    institution: Institution;
    persistent_account_id: string;
    name: string;
    official_name: string;
    mask: string;
    subtype: string;
    type: string;
    available_balance: number;
    current_balance: number;
    iso_currency_code: string;
    balance_limit: number | null;
    unofficial_currency_code: string | null;
    user_id: string;
    created_at: string;
    updated_at: string;
  }

// interface AccountInfo {
//     account_id: string;
//     available_balance: number | null;
//     balance_limit: number | null;
//     created_at: string;
//     current_balance: number;
//     id: number;
//     iso_currency_code: string;
//     item_id: string;
//     mask: string;
//     name: string;
//     official_name: string;
//     persistent_account_id: string;
//     subtype: string;
//     type: string;
//     unofficial_currency_code: string | null;
//     updated_at: string;
//     user_id: string;
// }

const TransactionSlice = createSlice({
    name: "transaction",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLinkTokenAsync.pending, (state) => {
                state.linkStatus = STATUS.LOADING;
            })
            .addCase(fetchLinkTokenAsync.fulfilled, (state, action) => {
                state.linkStatus = STATUS.SUCCESS;
                state.link_token = action.payload.link_token || null;
                state.hostedLink= action.payload.hosted_link_url || null;
                state.error = null; // Clear any previous error
            })
            .addCase(fetchLinkTokenAsync.rejected, (state, action) => {
                state.linkStatus = STATUS.FAILED;
                state.error = action.payload as string || null;
            })
            .addCase(fetchPublicAccessTokenAsync.pending, (state) => {
                state.publicAccessTokenStatus = STATUS.LOADING;
            })
            .addCase(fetchPublicAccessTokenAsync.fulfilled, (state, action) => {
                state.publicAccessTokenStatus = STATUS.SUCCESS;
                state.publicAccessToken = action.payload.link_token || null;
                state.error = null; // Clear any previous error
            })
            .addCase(fetchPublicAccessTokenAsync.rejected, (state, action) => {
                state.publicAccessTokenStatus = STATUS.FAILED;
                state.error = action.payload as string || null;
            })
            .addCase(fetchAccountDetailAsync.pending, (state) => {
                state.accountStatus = STATUS.LOADING;
            })
            .addCase(fetchAccountDetailAsync.fulfilled, (state, action) => {
                state.accountStatus = STATUS.SUCCESS;
                state.accounts = action.payload;
                state.error = null; // Clear any previous error
            })
            .addCase(fetchAccountDetailAsync.rejected, (state, action) => {
                state.accountStatus = STATUS.FAILED;
                state.error = action.payload as string || null;
            });
    },
});

export default TransactionSlice.reducer;
