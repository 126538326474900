import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchAccountDetail, fetchLinkToken, fetchPublicAccessToken } from "../../services/TransactionService";


export const fetchLinkTokenAsync = createAsyncThunk(
  "user/fetchLinkToken",
  async (data:any, thunkAPI) => {
    try {
      const response = await fetchLinkToken(data);
      return response; // Ensure that `response.data` contains `link_token`.
    } catch (error) {
      let errorMessage = "An unknown error occurred";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const fetchPublicAccessTokenAsync = createAsyncThunk(
  "user/fetchPublicAccessToken",
  async (data: any, thunkAPI) => {
    try {
      const response = await fetchPublicAccessToken(data);
      return response; 
    } catch (error) {
      let errorMessage = "An unknown error occurred";

      if (error instanceof Error) {
        errorMessage = error.message;
      }

      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);


export const fetchAccountDetailAsync = createAsyncThunk(
  "user/fetchAccountDetail",
  async (_, thunkAPI) => {
    try {
      const response = await fetchAccountDetail();
      return response; 
    } catch (error) {
      let errorMessage = "An unknown error occurred";

      if (error instanceof Error) {
        errorMessage = error.message;
      }

      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);


